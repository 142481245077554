<template>
    <section class="h-100">
        <div class="row mx-0 h-100">
            <div class="col-xl col-lg col-md col-sm col px-0 h-100 overflow-auto custom-scroll border-right">
                <div class="row mx-0 border-bottom" style="height:56px;">
                    <div class="col h-100 d-middle-center cr-pointer f-14" :class="chat == 5 ? 'border-b-black text-general' : ''" @click="chat = 5">
                        Chat {{ $config.vendedor }}
                    </div>
                    <div v-if="detalle.id_pedido" class="col h-100 d-middle-center cr-pointer f-14" :class="chat == 2 ? 'border-b-black text-general' : ''" @click="chat = 2">
                        Chat {{ $config.cliente }}
                        <!-- <div class="tres-puntos text-center cr-pointer py-1 px-3">
                        </div> -->
                    </div>
                </div>
                <div class="height-chat-padre">
                    <chat v-if="chat == 5" :tipo="chat" :user="id_pedido" :momento="1" @actualizar="actualizarPedidos" @actualizar-lectura="actualizarLectura" />
                    <chat v-else-if="chat == 2" :tipo="chat" :user="detalle.id_pedido" :momento="1" @actualizar="actualizarPedidos" @actualizar-lectura="actualizarLectura" />
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md col-sm col px-0 h-100">
                <div class="d-flex f-14 mx-0 px-2 border-bottom" style="height:56px;">
                    <div
                    v-for="tab in tabs" :key="tab.id"
                    class="col px-0 h-100 d-middle-center text-center cr-pointer"
                    :class="activa==tab.components?'border-b-black text-general':''"
                    @click="activa=tab.components"
                    >
                        {{ tab.nombre }}
                    </div>
                </div>
                <div class="height-components overflow-auto custom-scroll" style="height:calc(100vh - 275px);">
                    <component :is="activa" :finalizado="activa=='ChatLeechero' || activa=='ChatCliente' ? true :false" />
                    <!-- <detalle v-show="activa == 'Detalle'" />
                    <productos v-show="activa == 'Productos'" />
                    <chat-leechero v-show="activa == 'Chat2'" :finalizado="true" />
                    <chat-cliente v-show="activa == 'Reclamo'" :finalizado="true" /> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import ReclamosLeecheros from '~/services/chat/chat'

export default {
    components: {
        //Detalle: () => import('./components/detalleLeechero'),
        Detalle: () => import('~/pages/pedidos/components/detalleAdminPedido.vue'),
        Productos: () => import('./components/productosLeechero'),
    },
    props:{

        vista: {
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            activa: 'Detalle',
            tabs: [
                { id: 1, nombre: 'Detalle', components: 'Detalle'},
                { id: 2, nombre: 'Productos', components: 'Productos'},
            ],
            chat: 5
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            detalle: 'pedidos/pedidos_admin/pedido'
        }),
    },
    watch: {
        chat(){
            this.actualizarUltimaLecturaChat()
        }
    },
    destroyed(){
        this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
        this.$store.commit('pedidos/pedidos_admin/set_productos', [])
    },
    methods: {
        cerrarSoporte(){
            this.$refs.modalCerrarSoporte.toggle()
        },
        async actualizarPedidos(){
            try {
                this.$emit('actualizar')
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarLectura(){
            console.log('-----  ----actualizarLecturaa-   -------', );
            await this.actualizarUltimaLecturaChat()
        },
        async actualizarUltimaLecturaChat(){
            try {
                const enviar ={
                    id_dinamico: this.chat == 5 ? this.id_pedido : this.detalle.id_pedido,
                    tipo: this.chat
                }
                const {data} = await ReclamosLeecheros.actualizarUltimaLecturaChat(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.height-chat-padre{
    height:calc(100vh - 214px) !important;
}
.height-components{
    height:calc(100vh - 217px) !important;
}

@media (max-width:1000px){
    .height-chat-padre{
        height:calc(100vh - 274px) !important;
    }
    .height-components{
        height:calc(100vh - 275px) !important;
    }
}
</style>
